.sResume-data {
    text-align: center;
}
.sResume-description {
    margin-bottom: var(--mb-2);
}
.section-listing {
    margin-bottom: var(--mb-1);
}

