.button {
    display: inline-block;
    background-color: var(--first-colour);
    color: var(--title-colour);
    padding: 1rem 1.75rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
    transition: .3s;
    cursor: pointer;
}
.button:hover {
    background-color: var(--first-colour-alt);
}
