.projects-container {
    background: var(--container-color);
    border-radius: 1rem;
    padding: 1.5rem 0 1.5rem;
    row-gap: .75rem;
    margin-bottom: var(--mb-1);
}
.data {
    text-align: center;
}
.title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-1);

}
.sProject-description {
    margin-bottom: var(--mb-1);
    padding: 0 1.5rem;
}
.sProject-buttons {
    display: flex;
    justify-content: space-around;
    padding-left: 10%;
    padding-right: 10%;
}
.sProject-buttons a {
    width: 40%;
}
.tech-list {
    margin-bottom: var(--mb-0-75)
}

/* Media Breakpoints */
@media screen and (max-width: 576px) {
    .sProject-buttons {
        flex-direction: column;
        row-gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .sProject-buttons a {
        width: 100%;
    }
}

/* Media Breakpoints */
/*@media screen and (max-width: 576px) {*/
/*    .projects-container {*/
/*        grid-template-columns: .8fr;*/
/*        justify-content: center;*/
/*    }*/
/*}*/
