.nav-header {
    width: 100%;
    background: var(--body-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logo {
    display: flex;
    align-items: center;
    column-gap: .5rem;
    font-weight: var(--font-medium);
}
.nav-logo-img {
    width: 1.25rem;
}
.nav-link,
.nav-logo,
.nav-toggle,
.nav-close {
    color: var(--title-colour);
}
.nav-toggle {
    font-size: 1.25rem;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .nav-menu {
        position: fixed;
        width: 100%;
        background: var(--container-color);
        top: -150%;
        left: 0;
        padding: 3.5rem 0;
        transition: .4s;
        -webkit-transition-property: top;
        -webkit-transition-duration: .4s;
        z-index: var(--z-fixed);
        border-radius: 0 0 1.5rem 1.5rem;
    }
}
.nav-img {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}
.nav-close {
    font-size: 1.8rem;
    position: absolute;
    top: .5rem;
    right: .7rem;
    cursor: pointer;
}
.nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
}
.nav-link {
    text-transform: uppercase;
    font-weight: var(--font-black);
    transition: .4s
}
.nav-link:hover {
    color: var(--text-colour);
}

.show-menu {
    top: 0;
}

.scroll-header {
    background: var(--container-color);
    transition: 0.4s;
    -webkit-transition-property: background;
    -webkit-transition-duration: .4s;
}

.active-link {
    position: relative;
}
.active-link::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    left: 45%;
    width: 5px;
    height: 5px;
    background-color: var(--title-colour);
    border-radius: 50%;
}

.button-navMenu {
    color: var(--title-colour);
    border: 2px solid;
    background-color: transparent;
    border-radius: 3rem;
    padding: .75rem 1.5rem;
    transition: .4s;
}
.button-navMenu:hover {
    color: var(--text-colour);
}

/* Media Breakpoints */
@media screen and (min-width: 767px) {
    .nav {
        height: calc(var(--header-height) + 1.5rem);
    }
    .nav-close,
    .nav-toggle {
        display: none;
    }
    .nav-list {
        flex-direction: row;
        column-gap: 3rem;
    }
    .nav-link {
        text-transform: initial;
        font-weight: initial
    }
 }
