@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  /* might need this for desktop, hasnt done anything to that yet*/
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
    --header-height: 3.5rem;

    /* Colours */
    /*--hue: 340;*/
    --hue: 130; /* Chirstmas hue, works better for buttons */
    --first-colour: hsl(var(--hue), 91%, 30%);
    --first-colour-alt: hsl(var(--hue), 91%, 50%);
    --title-colour: hsl(var(--hue), 4%, 100%);
    --text-colour: hsl(var(--hue), 4%, 85%);
    --text-colour-light: hsl(var(--hue), 4%, 55%);

    /* Body Gradient -- Green */
    /*--body-color: linear-gradient(90deg, hsl(104, 28%, 40%) 0%, hsl(58, 28%, 40%) 100%);*/
    /*--container-color: linear-gradient(136deg, hsl(104, 28%, 35%) 0%, hsl(58, 28%, 35%) 100%);*/

    /* Body Gradient -- Miami Vice-ish */
    /*--body-color: linear-gradient(90deg, hsl(175, 28%, 40%) 0%, hsl(273, 28%, 40%) 100%);*/
    /*--container-color: linear-gradient(136deg, hsl(175, 28%, 35%) 0%, hsl(273, 28%, 35%) 100%);*/

    /* Body Gradient -- Christmas*/
    --body-color: linear-gradient(90deg, hsl(0, 0%, 40%) 0%, hsl(0, 45%, 53%) 100%);
    --container-color: linear-gradient(136deg, hsl(0, 0%, 42%) 0%, hsl(0, 45%, 53%) 100%);


    /* Font and typography */
    --body-font: 'Poppins', sans-serif;
    --biggest-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /* Font weight */
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-black: 900;

    /* Margins - Bottom */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    /*--mb-1-5: 6vw;*/
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;

    /*z index */
    --z-tooltip: 10;
    --z-fixed: 100;
}

/* Responsive typography */
@media screen and (min-width: 992px) {
    :root {
        --biggest-font-size: 4rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;

}
body {
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background: var(--body-color);
    color: var(--text-colour);
    overflow-x: hidden;
}

h1,h2,h3,h4 {
    color: var(--title-colour);
    font-weight: var(--font-semi-bold);
}
ul {
    list-style: none;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
}
button, input, textarea {
    border: none;
    outline: none
}
button {
    cursor: pointer;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

/* Reusable Classes */
.section {
    padding: 4.5rem 0 2rem;
}
.section-title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-2);
    text-align: center;
}

/* Layout */
.container {
    max-width: 968px;
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
    align-self: center;
}
.grid {
    display: grid;
}

.main {
    overflow: hidden;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 0.6rem;
    background: #413e3e;
}
::-webkit-scrollbar-thumb {
    background: #272525;
    border-radius: .5rem;
}

/* Breakpoints */
@media screen and (max-width: 320px) {
    .container {
        margin-left: var(--mb-2);
        margin-right: var(--mb-2);
    }
}

@media screen and (min-width: 767px) {
    body {
        margin: 0;
    }
    .section {
        padding: 7rem 0 2rem;
    }
}

@media screen and (min-width: 992px) {
    .container {
        margin-left: auto;
        margin-right: auto;
    }
    .section-title {
        font-size: var(--h1-font-size);
        margin-bottom: 3rem;
    }

}

.nav-header {
    width: 100%;
    background: var(--body-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
}

.nav {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logo {
    display: flex;
    align-items: center;
    grid-column-gap: .5rem;
    column-gap: .5rem;
    font-weight: var(--font-medium);
}
.nav-logo-img {
    width: 1.25rem;
}
.nav-link,
.nav-logo,
.nav-toggle,
.nav-close {
    color: var(--title-colour);
}
.nav-toggle {
    font-size: 1.25rem;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .nav-menu {
        position: fixed;
        width: 100%;
        background: var(--container-color);
        top: -150%;
        left: 0;
        padding: 3.5rem 0;
        transition: .4s;
        -webkit-transition-property: top;
        -webkit-transition-duration: .4s;
        z-index: var(--z-fixed);
        border-radius: 0 0 1.5rem 1.5rem;
    }
}
.nav-img {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}
.nav-close {
    font-size: 1.8rem;
    position: absolute;
    top: .5rem;
    right: .7rem;
    cursor: pointer;
}
.nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
}
.nav-link {
    text-transform: uppercase;
    font-weight: var(--font-black);
    transition: .4s
}
.nav-link:hover {
    color: var(--text-colour);
}

.show-menu {
    top: 0;
}

.scroll-header {
    background: var(--container-color);
    transition: 0.4s;
    -webkit-transition-property: background;
    -webkit-transition-duration: .4s;
}

.active-link {
    position: relative;
}
.active-link::before {
    content: '';
    position: absolute;
    bottom: -.75rem;
    left: 45%;
    width: 5px;
    height: 5px;
    background-color: var(--title-colour);
    border-radius: 50%;
}

.button-navMenu {
    color: var(--title-colour);
    border: 2px solid;
    background-color: transparent;
    border-radius: 3rem;
    padding: .75rem 1.5rem;
    transition: .4s;
}
.button-navMenu:hover {
    color: var(--text-colour);
}

/* Media Breakpoints */
@media screen and (min-width: 767px) {
    .nav {
        height: calc(var(--header-height) + 1.5rem);
    }
    .nav-close,
    .nav-toggle {
        display: none;
    }
    .nav-list {
        flex-direction: row;
        grid-column-gap: 3rem;
        column-gap: 3rem;
    }
    .nav-link {
        text-transform: initial;
        font-weight: initial
    }
 }

.header-content {
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.header-group {
    display: grid;
    position: relative;
    padding-top: 2rem;
}

.header-img {
    /*height: 250px;*/
    justify-self: center;
}

.header-details-img {
    position: absolute;
    right: .5rem;
}

.header-details-title,
.header-details-subtitle {
    display: block;
    font-size: var(--small-font-size);
    text-align: right;
}
.header-subtitle {
    font-size: var(--h3-font-size);
    text-transform: uppercase;
    margin-bottom: var(--mb-1);
}
.header-title {
    font-size: var(--biggest-font-size);
    font-weight: var(--font-black);
    line-height: 109%;
    margin-bottom: var(--mb-1);
}
.header-description {
    margin-bottom: var(--mb-1);
}

.header-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* Media Breakpoints */
@media screen and (max-width: 320px) {
    .header-buttons {
        flex-direction: column;
        grid-row-gap: 1rem;
        row-gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 767px) {
    .header-content {
        padding: 8rem 0 2rem;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
        gap: 4rem;
    }
}

@media screen and (min-width: 992px) {
    .header-content {
        padding-top: 9rem;
        grid-gap: 3rem;
        gap: 3rem;
    }
    .header-title {
        margin-bottom: var(--mb-1-5);
    }
    .header-data {
        padding-right: 2rem;
    }
    .header-description {
        margin-bottom: var(--mb-2-5);
    }
}

.about-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem 2rem;
    gap: 1rem 2rem;
    align-items: center;
}

.about-data {
    text-align: center;
}

.about-img {
    width: 120px;
    margin-bottom: var(--mb-0-75);
    transition: .3s;
    font-size: var(--biggest-font-size);
    color: var(--text-colour);
}

.title {
    margin-bottom: var(--mb-0-25);
}

.about-data:hover .about-img {
    transform: translateY(-.5rem);
}

/* Media Breakpoints */
@media screen and (max-width: 320px) {
    .about-container {
        grid-template-columns: .8fr;
        justify-content: center;
    }
}

@media screen and (min-width: 992px) {
    .about-container {
        grid-column-gap: 8rem;
        column-gap: 8rem;
    }
    .about-img {
        margin-bottom: var(--mb-1);
    }
}

.resume-container {
    grid-row-gap: 2rem;
    row-gap: 2rem;
    margin-bottom: var(--mb-1);
}
/* media breakpoints */
@media screen and (min-width: 767px) {
    .resume-container {
        padding: 1rem 0 2rem;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
        gap: 4rem;
    }
}

.sResume-data {
    text-align: center;
}
.sResume-description {
    margin-bottom: var(--mb-2);
}
.section-listing {
    margin-bottom: var(--mb-1);
}



.projects-container {
    background: var(--container-color);
    border-radius: 1rem;
    padding: 1.5rem 0 1.5rem;
    grid-row-gap: .75rem;
    row-gap: .75rem;
    margin-bottom: var(--mb-1);
}
.data {
    text-align: center;
}
.title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-1);

}
.sProject-description {
    margin-bottom: var(--mb-1);
    padding: 0 1.5rem;
}
.sProject-buttons {
    display: flex;
    justify-content: space-around;
    padding-left: 10%;
    padding-right: 10%;
}
.sProject-buttons a {
    width: 40%;
}
.tech-list {
    margin-bottom: var(--mb-0-75)
}

/* Media Breakpoints */
@media screen and (max-width: 576px) {
    .sProject-buttons {
        flex-direction: column;
        grid-row-gap: 0.5rem;
        row-gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .sProject-buttons a {
        width: 100%;
    }
}

/* Media Breakpoints */
/*@media screen and (max-width: 576px) {*/
/*    .projects-container {*/
/*        grid-template-columns: .8fr;*/
/*        justify-content: center;*/
/*    }*/
/*}*/

.contact-description {
    text-align: center;
    margin-bottom: var(--mb-1-5)
}

.contact-form {
    background: var(--container-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .75rem;
}
.contact-input {
    min-height: 15%;
    padding: .5rem .5rem;
    background: none;
    color: var(--title-colour);
    font-size: 16px;
}
.contact-input::placeholder {
    color: var(--text-colour);
}

.button {
    display: inline-block;
    background-color: var(--first-colour);
    color: var(--title-colour);
    padding: 1rem 1.75rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .3s;
}
.button:hover {
    background-color: var(--first-colour-alt);
}

.contact-thanks {
    font-weight: normal;
    letter-spacing: .05rem;
}

@media screen and (min-width: 767px) {
    .contact-container {
        grid-template-columns: repeat(2, -webkit-max-content);
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 992px) {
    .contact-form {
        padding: 1rem;
    }
    .contact-input {
        padding: 1rem 1rem;
    }
}


.footer {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    align-items: center;
}
.footer-container {
    display: flex;
    align-items: center;
    grid-column-gap: 2rem;
    column-gap: 2rem;
}
.logo {
    display: flex;
    align-items: center;
    grid-column-gap: .5rem;
    column-gap: .5rem;
    font-weight: var(--font-medium);
    color: var(--title-colour);
}
.logo-img {
    width: 20px;
}

.social {
    display: flex;
    justify-content: center;
    grid-column-gap: .75rem;
    column-gap: .75rem;
}
.social-link {
    font-size: var(--h2-font-size);
    color: var(--text-colour);
    transition: .3s;
    display: flex;
    align-self: center;
}
.social-link:hover {
    color: var(--title-colour)
}
.description {
    margin: 0;
}

.scroll-up {
    position: fixed;
    background: var(--container-color);
    color: var(--text-colour);
    opacity: .8;
    right: 1rem;
    bottom: -20%;
    display: inline-flex;
    padding: .3rem;
    border-radius: .25rem;
    z-index: var(--z-tooltip);
    font-size: var(--h3-font-size);
    transition: .4s;
}
.scroll-up:hover {
    background: var(--container-color);
    color: var(--title-colour);
    transform: translateY(-.25rem);
}

.show-scroll {
    bottom: 2rem;
}

/* Media Breakpoints */
@media screen and (min-width: 767px) {
    .footer-container {
        /*grid-template-columns: repeat(2, 1fr);*/
        grid-gap: 12rem;
        gap: 12rem;
    }
}

.button {
    display: inline-block;
    background-color: var(--first-colour);
    color: var(--title-colour);
    padding: 1rem 1.75rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
    transition: .3s;
    cursor: pointer;
}
.button:hover {
    background-color: var(--first-colour-alt);
}

.text-button {
    margin-bottom: 0;
    color: var(--title-colour);
    display: inline-flex;
    align-items: center;
    grid-column-gap: .5rem;
    column-gap: .5rem;
}

.buttonIcon {
    transition: .3s;
}
.text-button:hover .buttonIcon {
    transform: translateY(.3rem);
}

#snowfall {
    height: 100px;
    width: 100%;
    position: absolute;
    z-index: -1;
    overflow: hidden;
}

.snowflake {
    color: white;
    text-shadow: 0 0 2px #000;
    position: absolute;
    left: 0;
}

@keyframes fall {
    from {top: 0}
    to {top: 115%}
}

@keyframes sideSway {
    0% {-webkit-transform: translateX(0px)}
    50% {-webkit-transform: translateX(50px)}
    100% {-webkit-transform: translateX(0px)}
}

@keyframes fadeIn {
    from {opacity: 0%;}
}

#sidebar {
    border: 2px solid var(--title-colour);
    position: fixed;
    top: 10%;
    left: 0;
    bottom: 40%;
    padding: 5px;
    font-size: 1.5em;
}

#sidebarContent {
    display: flex;
    flex-direction: column;
}

#sidebar a {
    cursor: pointer;
}
#sidebar a:hover {
    opacity: 0.75;
}

