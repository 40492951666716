@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;900&display=swap');

:root {
    --header-height: 3.5rem;

    /* Colours */
    /*--hue: 340;*/
    --hue: 130; /* Chirstmas hue, works better for buttons */
    --first-colour: hsl(var(--hue), 91%, 30%);
    --first-colour-alt: hsl(var(--hue), 91%, 50%);
    --title-colour: hsl(var(--hue), 4%, 100%);
    --text-colour: hsl(var(--hue), 4%, 85%);
    --text-colour-light: hsl(var(--hue), 4%, 55%);

    /* Body Gradient -- Green */
    /*--body-color: linear-gradient(90deg, hsl(104, 28%, 40%) 0%, hsl(58, 28%, 40%) 100%);*/
    /*--container-color: linear-gradient(136deg, hsl(104, 28%, 35%) 0%, hsl(58, 28%, 35%) 100%);*/

    /* Body Gradient -- Miami Vice-ish */
    /*--body-color: linear-gradient(90deg, hsl(175, 28%, 40%) 0%, hsl(273, 28%, 40%) 100%);*/
    /*--container-color: linear-gradient(136deg, hsl(175, 28%, 35%) 0%, hsl(273, 28%, 35%) 100%);*/

    /* Body Gradient -- Christmas*/
    --body-color: linear-gradient(90deg, hsl(0, 0%, 40%) 0%, hsl(0, 45%, 53%) 100%);
    --container-color: linear-gradient(136deg, hsl(0, 0%, 42%) 0%, hsl(0, 45%, 53%) 100%);


    /* Font and typography */
    --body-font: 'Poppins', sans-serif;
    --biggest-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /* Font weight */
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-black: 900;

    /* Margins - Bottom */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    /*--mb-1-5: 6vw;*/
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;

    /*z index */
    --z-tooltip: 10;
    --z-fixed: 100;
}

/* Responsive typography */
@media screen and (min-width: 992px) {
    :root {
        --biggest-font-size: 4rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html {
    scroll-behavior: smooth;

}
body {
    margin: var(--header-height) 0 0 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background: var(--body-color);
    color: var(--text-colour);
    overflow-x: hidden;
}

h1,h2,h3,h4 {
    color: var(--title-colour);
    font-weight: var(--font-semi-bold);
}
ul {
    list-style: none;
}
a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
}
button, input, textarea {
    border: none;
    outline: none
}
button {
    cursor: pointer;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

/* Reusable Classes */
.section {
    padding: 4.5rem 0 2rem;
}
.section-title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-2);
    text-align: center;
}

/* Layout */
.container {
    max-width: 968px;
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
    align-self: center;
}
.grid {
    display: grid;
}

.main {
    overflow: hidden;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 0.6rem;
    background: #413e3e;
}
::-webkit-scrollbar-thumb {
    background: #272525;
    border-radius: .5rem;
}

/* Breakpoints */
@media screen and (max-width: 320px) {
    .container {
        margin-left: var(--mb-2);
        margin-right: var(--mb-2);
    }
}

@media screen and (min-width: 767px) {
    body {
        margin: 0;
    }
    .section {
        padding: 7rem 0 2rem;
    }
}

@media screen and (min-width: 992px) {
    .container {
        margin-left: auto;
        margin-right: auto;
    }
    .section-title {
        font-size: var(--h1-font-size);
        margin-bottom: 3rem;
    }

}
