.contact-description {
    text-align: center;
    margin-bottom: var(--mb-1-5)
}

.contact-form {
    background: var(--container-color);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: .75rem;
}
.contact-input {
    min-height: 15%;
    padding: .5rem .5rem;
    background: none;
    color: var(--title-colour);
    font-size: 16px;
}
.contact-input::placeholder {
    color: var(--text-colour);
}

.button {
    display: inline-block;
    background-color: var(--first-colour);
    color: var(--title-colour);
    padding: 1rem 1.75rem;
    border-radius: .5rem;
    font-weight: var(--font-medium);
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: .3s;
}
.button:hover {
    background-color: var(--first-colour-alt);
}

.contact-thanks {
    font-weight: normal;
    letter-spacing: .05rem;
}

@media screen and (min-width: 767px) {
    .contact-container {
        grid-template-columns: repeat(2, max-content);
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 992px) {
    .contact-form {
        padding: 1rem;
    }
    .contact-input {
        padding: 1rem 1rem;
    }
}

